/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it
export const shouldUpdateScroll = ({ routerProps: { location }, getSavedScrollPosition, }) => {
    // hack to override `shouldUpdateScroll` behavior to bypass useQueryParams in product-checkout
    if (location.search.includes('q=') || location.search.includes('audienceFilters') ||
      location.search.includes('engagementFilters=') || location.search.includes('formatFilters=') ||
      location.search.includes('focusFilters') || location.search.includes('topicsFilters=') ||
      location.search.includes('actionFilters')) {
      return false;
    }
    const currentPosition = getSavedScrollPosition(location);
    return currentPosition || [0, 0];
  };

if (typeof window !== "undefined") { require("typeform-elements/dist/embed"); }
