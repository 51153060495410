// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-jsx": () => import("./../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-browse-jsx": () => import("./../../src/pages/browse.jsx" /* webpackChunkName: "component---src-pages-browse-jsx" */),
  "component---src-pages-connect-jsx": () => import("./../../src/pages/connect.jsx" /* webpackChunkName: "component---src-pages-connect-jsx" */),
  "component---src-pages-get-started-jsx": () => import("./../../src/pages/get-started.jsx" /* webpackChunkName: "component---src-pages-get-started-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-learn-more-jsx": () => import("./../../src/pages/learn-more.jsx" /* webpackChunkName: "component---src-pages-learn-more-jsx" */)
}

